import { IColumn, MessageBar, MessageBarType } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useAuthInContext } from "@mevodo/mv-react-authentication";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBreadcrumbManager } from "../lib/components/DetailsListHelper/BreadcrumbManager";
import { ISubCommandDefinition, useColumnsBuilder } from "../lib/components/DetailsListHelper/ColumnsBuilder";
import { NavigationalDetailsList } from "../lib/components/DetailsListHelper/NavigationalDetailsList";
import { useSelectionManager } from "../lib/components/DetailsListHelper/SelectionManager";
import { ConfirmDialog, useConfirmDialog } from "../lib/ds/components/ConfirmDialog";
import { PageHeader } from "../lib/ds/components/PageHeader";
import { useQuery } from "../lib/ds/components/RouterExtensions";
import { useCommandBarService } from "../lib/components/DetailsListHelper/ComandBarService";
import { AddCustomerDialog } from "./AddCustomerDialog";
import { AddCustomerGroupDialog } from "./AddCustomerGroupDialog";
import { AddOrEditDiscountProfileDialog } from "./AddOrEditDiscountProfileDialog";
import { ImportCustomerDatasourceDialog } from "./ImportCustomerDatasourceDialog";
import { ManageResourceAccess } from "./ManageResourceAccess";
import { ReadOnlyCustomerDatasourceDialog } from "./ReadOnlyCustomerDatasourceDialog";
import { ICustomerManagementItem, ServiceProviderCustomerManagementUIService } from "./ServiceProviderCustomerManagementUIService";
import { AddCustomerDashboard } from "./AddCustomerDashboard";
import { SearchCustomerPanel } from "./SearchCustomerPanel";
import { SdkCustomerDashboardClient } from "../lib/sdk/SdkCustomerDashboardClient";
import { useNotifications } from "reapop";
import { AddOrEditCustomerVaultDialog } from "./AddOrEditCustomerVaultDialog";
import { AddOrEditCustomerDatasource } from "./AddOrEditCustomerDatasource";
import { MoveResourceDialog } from "./MoveResourceDialog";
import numbro from 'numbro';
import { AddOrEditCustomerSubscriptions } from "./AddOrEditCustomerSubscriptions";
import { extractErrorMessage } from "../lib/sdk/SdkClientErrorExtractor";
import { useSubscriptionContext } from "../lib/authentication/AuthenticationSubscriptionContext";
import { EditCustomerDialog } from "./EditCustomerDialog";
import { ShowEffectiveDiscountProfilesDialog } from "./ShowEffectiveDiscountProfilesDialog";
import { AddOrEditCustomPropertyProfileDialog } from "./AddOrEditCustomPropertyProfileDialog";
import { DeployFromTemplateCustomerDialog } from "./DeployFromTemplateCustomerDialog";
import { getCurrencySymbol } from "../lib/common/CurrencyHelpers";
import { SyncDatasourcePeriodSelectorPanel } from "./SyncDatasourcePeriodSelectorPanel";

interface IItemOpenRequest {
    item: ICustomerManagementItem
    operation: string
}

export interface IServiceProviderCustomerManagementProps {}

export function ServiceProviderCustomerManagement(props: IServiceProviderCustomerManagementProps) {
        
    // allow notifications
    const { notify } = useNotifications()
    
    // get the current tenant state
    const { tenantId } = useParams()      
    const { parent, type } = useQuery();

    // get the authenticaiton 
    const auth = useAuthInContext(tenantId as string)

    // get the subscription context
    const sub = useSubscriptionContext()    
                    
    // establish the uiService
    const uiService = new ServiceProviderCustomerManagementUIService(tenantId ? tenantId : '', auth.currentToken ? auth.currentToken : '')

    // establish the item state
    const [error, setError] = useState<string>('')    
    const [items, setItems] = useState<ICustomerManagementItem[]>([])
    const [parentItem, setParentItem] = useState<ICustomerManagementItem>()    
    const [partnerChannelItem, setPartnerChannelItem] = useState<ICustomerManagementItem | undefined>()    
    const [itemtoEdit, setItemToEdit] = useState<ICustomerManagementItem>()    
    const [itemtoOpen, setItemToOpen] = useState<IItemOpenRequest>()    
    const [isLoading, { setTrue: setLoading, setFalse: resetLoading}] = useBoolean(false)
    
    const [breadCrumbitems, breadCrumbmanager] = useBreadcrumbManager<ICustomerManagementItem>(items, 'id', 'name', 'parentId', 'type', { 'customer': 'group', 'group': 'group' })

    // inject the state for the command bar
    const [isAddCustomerOpen, { setTrue: showAddCustomer, setFalse: hideAddCustomer}] = useBoolean(false)
    const [isAddCustomerGroupOpen, { setTrue: showAddCustomerGroup, setFalse: hideAddCustomerGroup}] = useBoolean(false)
    const [isAddCustomerDatasourceOpen, { setTrue: showAddCustomerDatasource, setFalse: hideAddCustomerDatasource}] = useBoolean(false)        
    const [isAddCustomerDashboardOpen, { setTrue: showAddCustomerDashboard, setFalse: hideAddCustomerDashboard}] = useBoolean(false)
    const [isAddCustomerVaultOpen, { setTrue: showAddCustomerVault, setFalse: hideAddCustomerVault}] = useBoolean(false)
    const [isReadOnlyDatasourceOpen, { setTrue: showReadOnlyDatasource, setFalse: hideReadOnlyDatasource}] = useBoolean(false)        
    const [isAddDiscountProfileOpen, { setTrue: showAddDiscountProfile, setFalse: hideAddDiscountProfile}] = useBoolean(false)        
    const [isAddCustomPropertyProfileOpen, { setTrue: showAddCustomPropertyProfile, setFalse: hideCustomPropertyProfile}] = useBoolean(false)        
    const [isBulkDeleteEnabled, { setTrue: enableBulkDelete, setFalse: disableBulkDelete}] = useBoolean(false)    
    const [isBulkSyncEnabled, { setTrue: enableBulkSync, setFalse: disableBulkSync}] = useBoolean(false)    
    const [isManageAccessOpen, { setTrue: showManageAccess, setFalse: hideManageAccess}] = useBoolean(false)
    const [isImportOpen, { setTrue: showImport, setFalse: hideImport}] = useBoolean(false)
    const [isCustomerSelectorOpen, { setTrue: showCustomerSelector, setFalse: hideCustomerSelector}] = useBoolean(false)
    const [isPeriodSelectorOpen, { setTrue: showPeriodSelector, setFalse: hidePeriodSelector}] = useBoolean(false)
    const [isMoveResourcesOpen, { setTrue: showMoveResources, setFalse: hideMoveResources}] = useBoolean(false)
    const [isCustomerSubscriptionOpen, { setTrue: showCustomerSubscriptions, setFalse: hideCustomerSubscriptions}] = useBoolean(false)    
    const [isEffectiveDiscountProfilesOpen, { setTrue: showEffectiveDiscountProfiles, setFalse: hideEffectiveDiscountProfiles}] = useBoolean(false)    
    const [isEditCustomerOpen, { setTrue: showEditCustomer, setFalse: hideEditCustomer}] = useBoolean(false)
    const [isDeployFromTemplateCustomerOpen, { setTrue: showDeployFromTemplateCustomer, setFalse: hideDeployFromTemplateCustomer}] = useBoolean(false)    

    const [columns, setColumns] = useState<IColumn[]>([])
    const [requesteGroupType, setRequestedGroupType] = useState<'standard' | 'partnerchannel'>('standard')
    
    // adjust the parentid 
    const parentId = parent ? parent : 'root'
    const typeId = type ? type : 'group'
    
    // ensure we load the tree initialy
    useEffect(() => {   
        
        // set the loading 
        setLoading()

        // load the item we are currently sitting on
        uiService.loadGroupItem(parentId, typeId).then((item) => {
        
            // ensure the breadcrumb manager can load items         
            // dynamically if needed
            breadCrumbmanager.onLoadItem(async (key: string, type: string) => {
                const loadedGroup = await uiService.loadGroupItem(key, type)
                return loadedGroup
            })
            
            // load the partnerChannelItem just in case we are on a partnerChannel
            uiService.loadPartnerChannelItem(item.id).then((loadedPartnerChannelItem) => {

                // set the partnerChannelItem
                setPartnerChannelItem(loadedPartnerChannelItem)

                // set the correct item
                setParentItem(item)

                // configure the breadcrumb
                breadCrumbmanager.activateItem(item, (activeItem) => '?parent=' + activeItem.id).then(() => {
                            
                    // load the correct customer tree        
                    return uiService.loadItems(parentId, typeId).then((items) => {
                        
                        setItems(items)
                        resetLoading()
                        setError('')

                    })
                })
            })

        }).catch((reason) => {                    
            setError(extractErrorMessage(reason))
            resetLoading()
        })   
    // eslint-disable-next-line     
    }, [parentId])
        
    const reload = () => {
        setLoading()
        setError('')
        uiService.loadItems(parentId, typeId).then((items)  => {
            setItems(items)
            resetLoading()
        }).catch((reason) => {                    
            setError(extractErrorMessage(reason))
            resetLoading()
        })  
    }

    const handleDissmissWithReload = (cb: () => void) => {
        return () => {
            cb()
            setItemToEdit(undefined)
            reload()
        }
    }     

    // callback to remove the selected items
    const [selectedItems, selection] = useSelectionManager<ICustomerManagementItem>();
    const [confirmDialog, confirmDialogService] = useConfirmDialog()

    const deleteSelectedItemsConfirmed = () => {
        setLoading()         
        return uiService.deleteItems(selectedItems).then(() => {
            reload()
        })    
    } 

    const deleteSelectedItems = () => {  
        var notEditableDatasources = selectedItems.find(d => d.type === 'datasource' && !d.editable)

        if (notEditableDatasources) {
            confirmDialogService.show(
                'Deleting ' + selectedItems.length + ' item' + (selectedItems.length > 1 ? 's' : ''), 
                'The selected items contain managed datasources. These cannot be deleted, please adjust the selection and try again!');
        } else {
            confirmDialogService.ask(
                'Deleting ' + selectedItems.length + ' item' + (selectedItems.length > 1 ? 's' : ''), 
                'Do you really want to remove the selected items? All data associated with these items will be removed, restoring data is not possible!',
                deleteSelectedItemsConfirmed, 
                () => { 
                    reload()
                    return Promise.resolve() 
                })
                     
        }
    }

    const moveSelectedItems = () => { 
        var notMovableItems = selectedItems.find(d => d.type !== 'customer')
        if (notMovableItems) {
            confirmDialogService.show(
                'Operation not supported', 
                'The selected items contains unsupported resources. These cannot be moved, please adjust the selection and try again! Currently only customer objects can be moved.');                
        } else {        
            showMoveResources()
        }
    }
     
    const openCustomerPortalForActiveCustomer = () => {
        openCustomerPortal(parentItem as ICustomerManagementItem)         
    }

    const openCustomerPortal = (customer: ICustomerManagementItem) => {

        const notification = notify('Preparing customer portal for admin on behalf of access', 'loading')                
        uiService.openCustomerPortal(customer).then(() =>{            
            notify({...notification, dismissAfter: 3000, status: 'success'})
        }).catch(() =>{
            notify({...notification, dismissible: true, status: 'error'})
        })         
    }

    const openPartnerPortal = (group: ICustomerManagementItem) => {

        const notification = notify('Preparing partner portal for admin on behalf of access', 'loading')                
        uiService.openPartnerPortal(group).then(() =>{            
            notify({...notification, dismissAfter: 3000, status: 'success'})
        }).catch(() =>{
            notify({...notification, dismissible: true, status: 'error'})
        })         
    }

    const openDeployFromTemplateCustomer = (customer: ICustomerManagementItem) => {
        setItemToEdit(customer)
        showDeployFromTemplateCustomer()
    }

    const editDashboardInCustomerContext = (item: ICustomerManagementItem) => {               
        setItemToOpen({item: item, operation: 'edit'})
    }

    const editCustomer = (item: ICustomerManagementItem) => {               
        setItemToEdit(item)       
        showEditCustomer()
    }

    const copyCustomerPortalLinkToClipboard = (item: ICustomerManagementItem) => {
        
        let discoveredHost = 'http://localhost:3000'
        if (window.location.hostname !== 'localhost') {
            discoveredHost = 'https://' + window.location.hostname             
        }
        
        const launcherUri = discoveredHost+ process.env.PUBLIC_URL + '/welcome/' + tenantId + '/customers/' + item.id + '/launch'

        
        navigator.clipboard.writeText(launcherUri)
        notify('Copied customer portal launch url to clipboard', 'success', { dismissible: true, dismissAfter: 2000 })                
    }

    const searchForCustomer = () => { 
        setItemToEdit(undefined)       
        showCustomerSelector()                   
    }

    const addStandardCustomerGroup = () => {        
        setRequestedGroupType('standard')
        showAddCustomerGroup()
    }

    const addPartnerChannelCustomerGroup = () => {        
        setRequestedGroupType('partnerchannel')
        showAddCustomerGroup()
    }

    const openSetSubscriptionsDialog = (item: ICustomerManagementItem) => {
        setItemToEdit(item)
        showCustomerSubscriptions()    
    }

    const openEffectiveDiscountProfilesDialog = (item: ICustomerManagementItem) => {
        setItemToEdit(item)
        showEffectiveDiscountProfiles()    
    }

    useEffect(() => {
        if (!itemtoOpen) { return }

        if (parentItem && parentItem.type === 'customer') {            
            uiService.openCustomerDashboard(parentItem as ICustomerManagementItem, itemtoOpen.item, itemtoOpen.operation === 'edit')                   
        } else {
            showCustomerSelector()
        }     
    // eslint-disable-next-line        
    }, [itemtoOpen])
    
    const createDefaultDashboard = () => {
        confirmDialogService.ask('Create a standard dashboard', 'Do you really want to create a default dashboard in this context?', () => {
            const dashboardClient = new SdkCustomerDashboardClient(tenantId as string, parentId, typeId, auth.currentToken as string)
            return dashboardClient.createCustomerDashboard('Standard Dashboard', parentId, typeId === 'customer' ? 'Customer' : 'CustomerGroup', true).then(() => {
                reload()                
            })                    
        })        
    }

    // build the columns 
    const columnsBuilder = useColumnsBuilder<ICustomerManagementItem>();

    useEffect(() => {
        
        const columnSubCommandsCustomerName: ISubCommandDefinition<ICustomerManagementItem>[] =  [
            { iconName: 'faPercent', description: 'Effective Discount/Uplift Profiles', elipsis: true, onClick: (item) => { openEffectiveDiscountProfilesDialog(item) }, filter: (item: ICustomerManagementItem) => item.type === 'customer' },                        
            { iconName: 'faFileCertificate', description: 'Manage Associated Subscriptions', elipsis: true, onClick: (item) => { openSetSubscriptionsDialog(item) }, filter: (item: ICustomerManagementItem) => item.type === 'customer' },                        
            { iconName: 'faPersonDolly', description: 'Deploy Reports & Dashboards', elipsis: true, onClick: (item) => { openDeployFromTemplateCustomer(item) }, filter: (item: ICustomerManagementItem) => item.type === 'customer' },                        
            { iconName: 'faChartLineUp', description: 'Open the portal in the perspective of the end customer', onClick: openCustomerPortal, filter: (item: ICustomerManagementItem) => item.type === 'customer' },        
            { iconName: 'faChartLineUp', description: 'Open the portal in the perspective of the partner', onClick: openPartnerPortal, filter: (item: ICustomerManagementItem) => item.subType === 'partnerchannel' },        
            { iconName: 'faPen', description: 'Design the dashboard in the context of a dedicated customer', onClick: (item) => { editDashboardInCustomerContext(item) }, filter: (item: ICustomerManagementItem) => item.type === 'dashboard' },        
            { iconName: 'faPen', description: 'Edit the customer properties', onClick: editCustomer, filter: (item: ICustomerManagementItem) => item.type === 'customer' },
            { iconName: 'faRocketLaunch', description: 'Copy link to Customer Portal into clipboard', elipsis: true, onClick: copyCustomerPortalLinkToClipboard, filter: (item: ICustomerManagementItem) => item.type === 'customer' }        
        ]
    
        const columnSubCommandsCustomerId: ISubCommandDefinition<ICustomerManagementItem>[] =  [
            { iconName: 'faClipboard', description: 'Copies content to the clipboard', onClick: (item: ICustomerManagementItem) => {
                navigator.clipboard.writeText(item.id)
                notify('Copied value to clipboard', 'success', { dismissible: true, dismissAfter: 2000 })                
            }}        
        ]

        const columnNavigationOperation = (item: ICustomerManagementItem, opening: boolean) => {
            
            const toLocation = '?parent=' + item.id + '&type=' + item.type                  
            if (!opening) { return toLocation; }
            
            if (item.type === 'customer' || item.type === 'group') {
                return toLocation;                                                                            
            } else if (item.type === 'discountProfile') {
                setItemToEdit(item)
                showAddDiscountProfile()
                return '';
            } else if (item.type === 'customPropertyProfile') {
                setItemToEdit(item)
                showAddCustomPropertyProfile()
                return '';                
            } else if (item.type === 'datasource') {
                
                if (item.editable) {
                    setItemToEdit(item)
                    showAddCustomerDatasource()                    
                } else {
                    showReadOnlyDatasource();                    
                }

                return '';
            } else if (item.type === 'vault') {
                
                if (item.editable) {
                    setItemToEdit(item)
                    showAddCustomerVault()                    
                } else {
                    showAddCustomerVault();                    
                }

                return '';            
            } else {
                return '';
            }      
        }

        const smartSort = (fieldName: string ) => {
             
            const sortOperation = (name: string, descending: boolean, updatedColumns: IColumn[]) => {
                setItems(prevItems => uiService.sortItemsByField(prevItems, fieldName, descending)) 
                setColumns(updatedColumns)
            }   
            
            return sortOperation
        }    
        
        const smartSortByOperation = (operation: (item: ICustomerManagementItem) => any ) => {
             
            const sortOperation = (name: string, descending: boolean, updatedColumns: IColumn[]) => {
                setItems(prevItems => uiService.sortItemsByOperation(prevItems, descending, operation)) 
                setColumns(updatedColumns)
            }   
            
            return sortOperation
        }    

        const getCurrency = (item: ICustomerManagementItem) => {            
            return getCurrencySymbol(item.currency)        
        }

        const buildColumns = columnsBuilder
                .IconColumn({name: "Type", headerIcon: 'faBuildings', iconName: (item) => item.extraIcon ? item.extraIcon : 'fac' + item.type, to: columnNavigationOperation})
                .TextColumn({name: "Name", value: 'name', maxWidth: 300, to: columnNavigationOperation, sort: smartSort('name'), subCommands: columnSubCommandsCustomerName})            
                .TextColumn({name: "Id", value: "id", maxWidth: 280, to: columnNavigationOperation, sort: smartSort('id'), subCommands: columnSubCommandsCustomerId })            
                
                .CurrencyColumn({name: "Consumption (R)", currency: (item) => getCurrency(item), value: (ds) => ds.consumptionRP ? numbro(ds.consumptionRP).format('(0.00)') : '0', maxWidth: 130, to: columnNavigationOperation, sort: smartSort('consumptionRP')})
                .CurrencyColumn({name: "Consumption (PG)", currency: (item) => getCurrency(item), value: (ds) => ds.consumptionPG ? numbro(ds.consumptionPG).format('(0.00)') : '0', maxWidth: 130, to: columnNavigationOperation, sort: smartSort('consumptionPG')})

                .PercentColumn({name: "AVG Discount", value: (ds) => !ds.consumptionPG || !ds.consumptionRP ? 0 : 1 - (ds.consumptionRP / ds.consumptionPG), mantissa: 2, maxWidth: 130, to: columnNavigationOperation, sort: smartSortByOperation((item) => !item.consumptionPG || !item.consumptionRP ? 200 : item.consumptionRP / item.consumptionPG)})       

                .CurrencyColumn({name: "LM Consumption (R)", currency: (item) => getCurrency(item), value: (ds) => ds.lastPeriodConsumptionRP ? numbro(ds.lastPeriodConsumptionRP).format('(0.00)') : '0', maxWidth: 130, to: columnNavigationOperation, sort: smartSort('lastPeriodConsumptionRP')})                                                
                .CurrencyColumn({name: "LM Consumption (PG)", currency: (item) => getCurrency(item), value: (ds) => ds.lastPeriodConsumptionPG ? numbro(ds.lastPeriodConsumptionPG).format('(0.00)') : '0', maxWidth: 130, to: columnNavigationOperation, sort: smartSort('lastPeriodConsumptionPG')})                

                .PercentColumn({name: "LM AVG Discount", value: (ds) => !ds.lastPeriodConsumptionRP || !ds.lastPeriodConsumptionPG ? 0 : 1 - (ds.lastPeriodConsumptionRP / ds.lastPeriodConsumptionPG), mantissa: 2, maxWidth: 130, to: columnNavigationOperation, sort: smartSortByOperation((item) => !item.lastPeriodConsumptionRP || !item.lastPeriodConsumptionPG ? 200 : item.lastPeriodConsumptionRP / item.lastPeriodConsumptionPG)})       

                .Build()   

        setColumns(buildColumns)                    
    // eslint-disable-next-line
    }, [])

    // build the command bar 
    const commandBarService = useCommandBarService()

    const enablePartnerChannelFeature =  true
    
    commandBarService.Command('new', 'New', 'faPlus')

    if (sub.subscriptions.find(s => s.id.endsWith('.serviceprovider'))) { commandBarService.SubCommand('newCustomer', 'Customer', 'faBuildings', showAddCustomer, typeId === 'customer') }
    commandBarService.SubCommand('newGroup', 'Group', 'faFolderPlus', addStandardCustomerGroup, typeId === 'customer')
    if (sub.subscriptions.find(s => s.id === 'subscription.cloudbilling.serviceprovider')) { commandBarService.SubCommand('newPartnerChannel', 'Partner Channel', 'faHandShake', addPartnerChannelCustomerGroup, enablePartnerChannelFeature ? (typeId === 'customer' || partnerChannelItem !== undefined) : true) }
    if (sub.subscriptions.find(s => s.id.startsWith('subscription.cloudbilling') || s.id.startsWith('hidden.subscription.cloubbilling'))) { commandBarService.SubCommand('newDiscountProfile', 'Discount/Uplift Profile', 'faPercent', showAddDiscountProfile) }
    commandBarService.SubCommand('newCustomPropertyProfile', 'Custom Property Profile', 'faTags', showAddCustomPropertyProfile) 
    if (sub.subscriptions.find(s => s.id.endsWith('.serviceprovider'))) { commandBarService.SubCommand('newDataSource', 'Datasource', 'faBinary', showAddCustomerDatasource, typeId !== 'customer') }
    commandBarService.SubCommand('newDashboard', 'Dashboard', 'faChartLineUp', showAddCustomerDashboard)            

    if (sub.subscriptions.find(s => s.id === 'subscription.slamonitoring.serviceprovider')) { commandBarService.SubCommand('newVault', 'Configuration Vault', 'faVault', showAddCustomerVault, typeId !== 'customer') }

    commandBarService.Command('openCustomerPortal', 'Customer Portal', 'faChartLineUp', openCustomerPortalForActiveCustomer, typeId !== 'customer' )        
        .Command('search', 'Search', 'faMagnifyingGlass', searchForCustomer)        
        .Command('move', 'Move', 'faBoxesPacking', moveSelectedItems, !isBulkDeleteEnabled || typeId === 'customer')        
        .Command('delete', 'Delete', 'faTrashCan', deleteSelectedItems, !isBulkDeleteEnabled)        
        .Command('refresh', 'Refresh', 'faRotate', reload)
        .Command('managedAccess', 'Manage Access', 'faLockA', showManageAccess)
        .Command('import', 'Import', 'faFileImport', showImport, typeId !== 'customer')
        .Command('sync', 'Synchronize', 'faCloudScale', showPeriodSelector, !isBulkSyncEnabled, typeId !== 'customer')
        .Command('defaultDashboard', 'Create Standard Dashboard', 'faRobot', createDefaultDashboard)
    
    // manage the selection    
    useEffect(() => {
        selectedItems.length > 0 ? enableBulkDelete() :disableBulkDelete()        
        selectedItems.filter(item => item.editable).length > 0 ? enableBulkSync() : disableBulkSync()
    // eslint-disable-next-line                 
    }, [selectedItems])
    
    return (
        <div className="mv-content-container">

            <PageHeader title={'Customer Management'}>            
                <span>All customers under your management are organized in the tree below. It's possible to assign different elements in the tree level to influence the result the end customer sees</span>
            </PageHeader>


            { error && error.length > 0 ? (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                    {'Error: ' + error}
                </MessageBar>
            ) : (<></>)}
    
            <NavigationalDetailsList 
                uniqueIdentifier={parentId}
                dataItems={items} 
                breadcrumbItems={breadCrumbitems} 
                commandBarService={commandBarService}
                columns={columns} 
                isLoading={isLoading}
                selection={selection} />

            <AddCustomerDialog isVisible={isAddCustomerOpen} dismissDialog={handleDissmissWithReload(hideAddCustomer)} parent={parentId} tenantId={tenantId as string} />
            <EditCustomerDialog isVisible={isEditCustomerOpen} dismissDialog={handleDissmissWithReload(hideEditCustomer)} parent={parentId} tenantId={tenantId as string} itemId={itemtoEdit && isEditCustomerOpen ? itemtoEdit.id : undefined}/>
            <AddCustomerGroupDialog isVisible={isAddCustomerGroupOpen} dismissDialog={handleDissmissWithReload(hideAddCustomerGroup)} parent={parentId} tenantId={tenantId as string} groupType={requesteGroupType} />
            <AddCustomerDashboard isVisible={isAddCustomerDashboardOpen} dismissDialog={handleDissmissWithReload(hideAddCustomerDashboard)} parent={parentId} parentType={typeId} tenantId={tenantId as string} />            
            <ReadOnlyCustomerDatasourceDialog isVisible={isReadOnlyDatasourceOpen} dismissDialog={hideReadOnlyDatasource} />
            <AddOrEditDiscountProfileDialog isVisible={isAddDiscountProfileOpen} dismissDialog={handleDissmissWithReload(hideAddDiscountProfile)} parent={parentId} parentType={typeId} tenantId={tenantId as string} itemId={itemtoEdit && isAddDiscountProfileOpen? itemtoEdit.id : undefined} />
            <AddOrEditCustomPropertyProfileDialog isVisible={isAddCustomPropertyProfileOpen} dismissDialog={handleDissmissWithReload(hideCustomPropertyProfile)} parent={parentId} parentType={typeId} tenantId={tenantId as string} itemId={itemtoEdit && isAddCustomPropertyProfileOpen? itemtoEdit.id : undefined} />
            <ConfirmDialog {...confirmDialog}/>
            <ManageResourceAccess isVisible={isManageAccessOpen} dismissDialog={handleDissmissWithReload(hideManageAccess)} tenantId={tenantId as string} resourceId={parentItem ? parentItem.id : ''} resourceName={parentItem ? parentItem.name : ''} resourceType={parentItem ? parentItem.type : ''} />
            <ImportCustomerDatasourceDialog isVisible={isImportOpen} dismissDialog={handleDissmissWithReload(hideImport)} parent={parentId} tenantId={tenantId as string} />
            <SearchCustomerPanel isVisible={isCustomerSelectorOpen} dismissDialog={() => { setItemToOpen(undefined); hideCustomerSelector() }} tenantId={tenantId as string} dashboardId={itemtoOpen ? itemtoOpen.item.id : ''} parentId={itemtoOpen ? itemtoOpen.item.parentId as string : ''}/>
            
            <SyncDatasourcePeriodSelectorPanel tenantId={tenantId as string} isVisible={isPeriodSelectorOpen} dismissDialog={handleDissmissWithReload(hidePeriodSelector)} selectedItems={selectedItems} parentId={parentId} />
            <MoveResourceDialog tenantId={tenantId as string} isVisible={isMoveResourcesOpen} dismissDialog={handleDissmissWithReload(hideMoveResources)} sourceParentGroupId={parentId} resourceIds={selectedItems ? selectedItems.map(i => i && i.id ? i.id : '') : []} />
            <AddOrEditCustomerSubscriptions isVisible={isCustomerSubscriptionOpen} dismissDialog={hideCustomerSubscriptions} tenantId={tenantId as string} customerId={itemtoEdit && isCustomerSubscriptionOpen ? itemtoEdit.id : undefined}/>

            <ShowEffectiveDiscountProfilesDialog isVisible={isEffectiveDiscountProfilesOpen} dismissDialog={hideEffectiveDiscountProfiles} tenantId={tenantId as string} itemId={itemtoEdit && isEffectiveDiscountProfilesOpen ? itemtoEdit.id : undefined} />
            <DeployFromTemplateCustomerDialog isVisible={isDeployFromTemplateCustomerOpen} dismissDialog={hideDeployFromTemplateCustomer} tenantId={tenantId as string} customerId={itemtoEdit && isDeployFromTemplateCustomerOpen ? itemtoEdit.id : undefined} />
            
            { /* Implemented with generic object model */ }
            <AddOrEditCustomerDatasource isVisible={isAddCustomerDatasourceOpen} dismissDialog={handleDissmissWithReload(hideAddCustomerDatasource)} tenantId={tenantId as string} itemId={itemtoEdit && isAddCustomerDatasourceOpen ? itemtoEdit.id : undefined} parent={parentId} />        
            <AddOrEditCustomerVaultDialog isVisible={isAddCustomerVaultOpen} dismissDialog={handleDissmissWithReload(hideAddCustomerVault)} parent={parentId} tenantId={tenantId as string} itemId={itemtoEdit && isAddCustomerVaultOpen ? itemtoEdit.id : undefined} />
        </div>
    ) 
}